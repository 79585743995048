/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Image, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-zprm8e --style3 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/13459/9a8771b6fa2d46368d2541e3f54be27f_bl=3_ove=000000x15__s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Subtitle className="subtitle-box fs--14" content={"- &nbsp;A R C H I T E K T U R A &nbsp;&amp; &nbsp;D E S I G N &nbsp;-<br><br><br>"}>
              </Subtitle>

              <Image src={"https://cdn.swbpg.com/t/13459/5b1786225ea2434aa240cfe6a5214d1d_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":840}} srcSet={"https://cdn.swbpg.com/t/13459/5b1786225ea2434aa240cfe6a5214d1d_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/5b1786225ea2434aa240cfe6a5214d1d_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/5b1786225ea2434aa240cfe6a5214d1d_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/5b1786225ea2434aa240cfe6a5214d1d_s=1400x_.png 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/3c85ea727c284fed9713ed9deccd7c39_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13459/3c85ea727c284fed9713ed9deccd7c39_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/3c85ea727c284fed9713ed9deccd7c39_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/3c85ea727c284fed9713ed9deccd7c39_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--14" content={"A R C H I T E K T O N I C K É &nbsp;S T U D I E &nbsp;&amp; &nbsp;V I Z U A L I Z A C E<br>"}>
              </Title>

              <Text className="text-box text-box--justify fs--13" content={"ARCHITEKTONICKOU STUDII POVAŽUJEME ZA JEDNU Z NEJZÁSADNĚJŠÍCH FÁZÍ STAVBY. ARCHITEKT SE V TÉTO ČÁSTI PROJEKTU SEZNAMUJE S ÚKOLEM, FORMULUJE SE ZADÁNÍ A PREZENTUJÍ SE POŽADAVKY INVESTORA. VĚTŠINU ČASU SE OVĚŘUJÍ RŮZNÁ ŘEŠENÍ, HLEDÁ SE KONCEPT A SJEDNOCUJÍ SE MYŠLENKY ARCHITEKTA I SAMOTNÉHO INVESTORA. PŘI STUDII JE DŮLEŽITÁ PŘEDEVŠÍM VZÁJEMNÁ SPOLUPRÁCE. HLEDÁ SE ŘEŠENÍ OBJEKTU, JEHO ORIENTACE A UMÍSTĚNÍ NA PARCELE, JAKÉ PROSTORY BUDE STAVBA OBSAHOVAT A NABÍZET, JAKÝ JE STYL INVESTORA A JEHO PRIVILEGIA PŘI NÁVRHU. NEMÉNĚ DŮLEŽITÝM BODEM JE PŘIZPŮSOBENÍ ŘEŠENÍ K SAMOTNÉ CENĚ REALIZACE."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/920edd657a6c4d2785b477e275420b36_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13459/920edd657a6c4d2785b477e275420b36_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/920edd657a6c4d2785b477e275420b36_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/920edd657a6c4d2785b477e275420b36_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--14" content={"P R O J E K T O V Á &nbsp;D O K U M E N T A C E &nbsp;<br><br>&amp; &nbsp;I N Ž E N Ý R S K Á &nbsp;Č I N N O S T"}>
              </Title>

              <Text className="text-box text-box--justify fs--13" content={"TATO DOKUMENTACE JE PODKLADEM PRO STAVEBNÍ ÚŘAD, DÁLE PAK PODKLADEM PRO SAMOTNOU REALIZACI STAVBY. STAVEBNÍ ZÁKONY URČUJÍ JAKÝ PROJEKT JE NA KTERÝ ZÁMĚR POTŘEBA. PROJEKTOVÁ DOKUMENTACE SE DĚLÍ NA NĚKOLIK STUPŇŮ, PŘEDEVŠÍM DLE ÚČELU A PODROBNOSTI ROZPRACOVÁNÍ PROJEKTU. ZAJIŠŤUJEME ŘEŠENÍ VŠECH TĚCHTO STUPŇŮ DOKUMENTACE. ROZSAH PROVÁDĚNÝCH PRACÍ JE ZÁVISLÝ DLE POTŘEB OBJEDNATELE, LZE DOHODNOUT JEN DÍLČÍ PLNĚNÍ INŽENÝRSKÉ ČINNOSTI, KDE SE JEDNÁ NAPŘÍKLAD O PŘÍPRAVU STAVBY, STAVEBNÍ DOZOR NEBO JEN DÍLČÍ KONZULTACI."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/edcb0902f8c5463fad52bd16f041d7dd_e=0x0x1293x1293_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13459/edcb0902f8c5463fad52bd16f041d7dd_e=0x0x1293x1293_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13459/edcb0902f8c5463fad52bd16f041d7dd_e=0x0x1293x1293_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13459/edcb0902f8c5463fad52bd16f041d7dd_e=0x0x1293x1293_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box fs--14" content={"D E S I G N &nbsp;I N T E R I É R Ů"}>
              </Title>

              <Text className="text-box text-box--justify fs--13" content={"SAMOTNÝ NÁVRH INTERIÉRŮ ČI JEHO REALIZACE JE DÁNA RESPEKTOVÁNÍM POŽADAVKŮ A PŘÁNÍ INVESTORA. NEJEDNÁ SE JEN O ROZMÍSTĚNÍ NÁBYTKU ARCHITEKTEM NEBO O ŘEŠENÍ BAREVNÉHO SLADĚNÍ. JEDNÁ SE TAKÉ NAPŘÍKLAD O ŘEŠENÍ OSVĚTLENÍ A VEDENÍ INSTALACÍ, O ROZMĚRY ZAŘIZOVACÍCH PŘEDMĚTŮ, JEJICH ODSTUPŮ A VZDÁLENOSTÍ OD SEBE, KTERÉ URČUJÍ POHODU V INTERIÉRU. ZABÝVÁME SE NÁVRHY INTERIÉRŮ URČENÝCH JAK K SOUKROMÉMU VYUŽITÍ, TAK VEŘEJNÝCH PROSTOR. AŤ UŽ SE JEDNÁ O NÁVRH ČI REALIZACI. SOUČINNOST ARCHITEKTA S DODAVATELI HRAJE DALŠÍ VÝZNAMNOU ROLI PŘI TVORBĚ VAŠEHO VYSNĚNÉHO NÁVRHU.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"citat"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":1670}}>
              
              <Text className="text-box fs--18 w--400 lh--16" content={"\" P O M O H U &nbsp;V Á M &nbsp;V Á Š &nbsp;S E N &nbsp;Z M Ě N I T &nbsp;V &nbsp;R E A L I T U \""}>
              </Text>

              <Text className="text-box fs--12" content={"N Á V R H Y &nbsp;I N T E R I É R Ů &nbsp;A &nbsp;E X T E R I É R Ů &nbsp;- &nbsp;O D &nbsp;S T U D I E &nbsp;K E &nbsp;S T A V E B N Í M U &nbsp;P O V O L E N Í &nbsp;- &nbsp;N Á V R H Y &nbsp;A &nbsp;R E A L I Z A C E<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"informace-2"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box fs--14" content={"J A K &nbsp;S P O L U P R A C O V A T&nbsp;<br><br>"}>
              </Title>

              <Text className="text-box text-box--justify fs--13" content={"NA ZAČÁTKU VŠEHO ZAČÍNÁ VZÁJEMNÁ SPOLUPRÁCE DOMLUVENÍM PODMÍNEK SPOLUPRÁCE, TERMÍNŮ A HONORÁŘE. VŠE SE LIŠÍ POŽADAVKY INVESTORŮ, AVŠAK MÝM ZÁJMEM JE PŘEDEVŠÍM OPTIMALIZOVAT PROJEKT K VAŠÍ ÚPLNÉ SPOKOJENOSTI. U KAŽDÉHO PROJEKTU ŘEŠÍM INDIVIDUÁLNÍ ŘEŠENÍ, NA MÍRU VAŠÍM POTŘEBÁM A ZAKLÁDÁM SI NA DOKONALE ODVEDENÉ PRÁCI.&nbsp;<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=860x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=350x_.png 350w, https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=660x_.png 660w, https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=860x_.png 860w, https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13459/08a7ae2e87a1412dbf4ac7a230ea96e0_s=2000x_.png 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box fs--14" content={"I N G . &nbsp;A R C H . &nbsp;J A N A &nbsp;L O R E N C O V Á"}>
              </Title>

              <Text className="text-box fs--12" content={"Hlaváčkova 3205, 440 01 Louny<br>+420 725 649 523<br>lorencovajana@gmail.com<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}